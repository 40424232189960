import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ButtonComponent,
  DropdownComponent,
  DropdownOptionComponent,
  IconButtonComponent,
  PillComponent,
} from '@dougs/ds';
import { SalesInvoice, SalesInvoicePaymentStatus } from '@dougs/sales-invoice/dto';
import { InvoiceDateComponent } from '../../../components/invoice/invoice-date/invoice-date.component';
import { AttachSalesInvoiceItemComponentService } from '../../../services/attach-sales-invoice-modal/attach-sales-invoice-item.component.service';

@Component({
  selector: 'dougs-attach-sales-invoice-item',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    DropdownComponent,
    DropdownOptionComponent,
    IconButtonComponent,
    InvoiceDateComponent,
    PillComponent,
  ],
  providers: [AttachSalesInvoiceItemComponentService],
  templateUrl: './attach-sales-invoice-item.component.html',
  styleUrls: ['../../../styles/sales-invoice-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachSalesInvoiceItemComponent {
  constructor(public readonly attachSalesInvoiceItemComponentService: AttachSalesInvoiceItemComponentService) {}

  protected readonly SalesInvoicePaymentStatus = SalesInvoicePaymentStatus;

  @Input() set salesInvoice(salesInvoice: SalesInvoice) {
    this.attachSalesInvoiceItemComponentService.setSalesInvoice(salesInvoice);
  }
}
