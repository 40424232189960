<ng-container *ngIf="attachSalesInvoiceModalComponentService.refreshSalesInvoice$ | async" />
<ng-container *ngIf="attachSalesInvoiceModalComponentService.loadMoreSalesInvoice$ | async" />
<ng-container *ngIf="attachSalesInvoiceModalComponentService.updateOperation$ | async" />
<div dougsModalTitle>
  <h6>Attacher une facture de vente</h6>
  <i
    class="fal fa-times"
    [dougsModalClose]="attachSalesInvoiceModalComponentService.tempAttachedSalesInvoices$ | async"
  ></i>
</div>
<div
  infiniteScroll
  [infiniteScrollDisabled]="attachSalesInvoiceModalComponentService.isLoading$()"
  [scrollWindow]="false"
  [immediateCheck]="true"
  (scrolled)="attachSalesInvoiceModalComponentService.loadMoreSalesInvoice()"
  dougsModalContent="xlarge"
  class="force-max-height"
>
  <dougs-attach-sales-invoice-list *ngIf="!attachSalesInvoiceModalComponentService.isLoading$()" />
  <dougs-list-skeleton *ngIf="attachSalesInvoiceModalComponentService.isLoading$()" />
</div>
<div dougsModalFooter>
  <dougs-button
    color="secondary"
    [dougsModalClose]="attachSalesInvoiceModalComponentService.tempAttachedSalesInvoices$ | async"
  >
    Terminer
  </dougs-button>
</div>
