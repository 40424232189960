<div
  class="invoice invoice--attach pl-24 pr-16 py-8"
  *ngIf="attachSalesInvoiceItemComponentService.salesInvoice$ | async as salesInvoice"
>
  <div class="invoice__title">
    <dougs-invoice-date [invoice]="salesInvoice"></dougs-invoice-date>
    <div class="invoice__title__label">
      <p class="tiny color-primary-700 invoice__title__label__reference">
        {{ salesInvoice.reference }}
      </p>
      <p class="tiny">{{ salesInvoice.label ? salesInvoice.label : '-' }}</p>
    </div>
  </div>
  <p class="tiny color-primary-700">{{ salesInvoice.clientName ? salesInvoice.clientName : '-' }}</p>
  <div class="cta-container">
    <dougs-pill *ngIf="salesInvoice.paymentStatus === SalesInvoicePaymentStatus.PAID" type="success">
      <div class="pill-icon__container color-green-700">
        <i class="fal fa-check pill-icon"></i>
        <p class="xtiny">Payée</p>
      </div>
    </dougs-pill>
    <dougs-pill *ngIf="salesInvoice.paymentStatus === SalesInvoicePaymentStatus.LATE" type="error">
      <div class="pill-icon__container color-red-700">
        <i class="fal fa-alarm-exclamation pill-icon"></i>
        <p class="xtiny">Retard de paiement</p>
      </div>
    </dougs-pill>
    <dougs-pill *ngIf="salesInvoice.paymentStatus === SalesInvoicePaymentStatus.WAITING" type="primary">
      <div class="pill-icon__container color-primary-700">
        <i class="fal fa-hourglass-clock pill-icon"></i>
        <p class="xtiny">Attente de paiement</p>
      </div>
    </dougs-pill>
  </div>
  <div>
    <dougs-pill class="ml-4" *ngIf="salesInvoice?.operationAttachments?.length > 0">
      {{ salesInvoice.operationAttachments.length }}
      <ng-container [ngPlural]="salesInvoice.operationAttachments.length">
        <ng-template ngPluralCase="=1">opération</ng-template>
        <ng-template ngPluralCase="other">opérations</ng-template>
      </ng-container>
    </dougs-pill>
    <dougs-pill
      class="ml-4"
      type="yellow"
      *ngIf="!salesInvoice?.operationAttachments?.length && salesInvoice.operationCandidate"
    >
      <i class="sparkles-icon fal fa-sparkles color-yellow-500 mr-4"></i>
      1 opération
    </dougs-pill>
  </div>
  <div class="text-right">
    @if (salesInvoice.amount || salesInvoice.netAmount) {
      @if (salesInvoice.amount) {
        <p class="tiny color-primary-700 bold">{{ salesInvoice.amount | currency: 'EUR' : 'symbol' : '0.0-2' }}</p>
      }
      @if (salesInvoice.netAmount) {
        <p class="tiny">{{ salesInvoice.netAmount | currency: 'EUR' : 'symbol' : '0.0-2' }} HT</p>
      }
    } @else {
      <p class="tiny color-primary-700">-</p>
    }
  </div>
  <div>
    <dougs-button
      (click)="attachSalesInvoiceItemComponentService.onAttachSalesInvoice()"
      *ngIf="!(attachSalesInvoiceItemComponentService.isAttached$ | async)"
      size="small"
      >Attacher
    </dougs-button>
    <dougs-button
      *ngIf="attachSalesInvoiceItemComponentService.isAttached$ | async"
      (click)="attachSalesInvoiceItemComponentService.onDetachSalesInvoice()"
      size="small"
      color="secondary"
      >Détacher
    </dougs-button>
  </div>
</div>
