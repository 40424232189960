import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { CompanyChanged, CompanyChangedStateService } from '@dougs/core/socket';
import { SalesInvoiceOperationsStateService } from '@dougs/operations/shared';
import { SalesInvoice } from '@dougs/sales-invoice/dto';
import { SalesInvoiceStateService } from '@dougs/sales-invoice/shared';

@Injectable({ providedIn: 'root' })
export class SalesInvoiceSocketService {
  constructor(
    private readonly salesInvoiceStateService: SalesInvoiceStateService,
    private readonly companyChangedStateService: CompanyChangedStateService,
    private readonly salesInvoiceOperationsStateService: SalesInvoiceOperationsStateService,
    private readonly route: ActivatedRoute,
  ) {}

  updateSalesInvoiceFromSocket$: Observable<void> = this.companyChangedStateService.companyChanged$.pipe(
    concatMap((companyChanged) => this.updateSalesInvoiceFromSocket(companyChanged)),
  );

  private async updateSalesInvoiceFromSocket(companyChanged: CompanyChanged): Promise<void> {
    if (companyChanged.modelName === 'salesInvoice' && companyChanged.type !== 'delete') {
      const salesInvoice: SalesInvoice | null = await this.salesInvoiceStateService.updateSalesInvoiceFromSocket(
        companyChanged.companyId,
        companyChanged.modelId.toString(),
      );
      const salesInvoiceQueryParams: string | null = this.route.snapshot.queryParamMap.get('salesInvoiceId');
      const salesInvoiceDrawerOpened: boolean = salesInvoiceQueryParams === salesInvoice?.id;
      if (salesInvoiceDrawerOpened && salesInvoice) {
        await this.salesInvoiceOperationsStateService.refreshOperations(salesInvoice);
        if (salesInvoice?.operationCandidate?.operation?.id && salesInvoice?.companyId) {
          await this.salesInvoiceOperationsStateService.refreshMatchedOperation(
            salesInvoice.companyId,
            salesInvoice.operationCandidate.operation.id,
          );
        } else {
          this.salesInvoiceOperationsStateService.resetMatchedOperation();
        }
        if (salesInvoice?.accrualOperationAttachment?.operation?.id && salesInvoice?.companyId) {
          await this.salesInvoiceOperationsStateService.refreshAccrualOperation(
            salesInvoice.companyId,
            salesInvoice.accrualOperationAttachment.operation.id,
          );
        } else {
          this.salesInvoiceOperationsStateService.resetAccrualOperation();
        }
      }
    }
  }
}
