import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import {
  ButtonComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { ListSkeletonComponent } from '../../components/skeletons/list/list-skeleton.component';
import { AttachSalesInvoiceModalComponentService } from '../../services/attach-sales-invoice-modal/attach-sales-invoice-modal.component.service';
import { AttachSalesInvoiceListComponent } from './attach-sales-invoice-list/attach-sales-invoice-list.component';

@Component({
  selector: 'dougs-attach-sales-invoice-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalFooterDirective,
    ModalContentDirective,
    ModalCloseDirective,
    ModalTitleDirective,
    ButtonComponent,
    ListSkeletonComponent,
    AttachSalesInvoiceListComponent,
    InfiniteScrollDirective,
  ],
  providers: [AttachSalesInvoiceModalComponentService],
  templateUrl: './attach-sales-invoice-modal.component.html',
  styleUrls: ['./attach-sales-invoice-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachSalesInvoiceModalComponent {
  constructor(public readonly attachSalesInvoiceModalComponentService: AttachSalesInvoiceModalComponentService) {}
}
