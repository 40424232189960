import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, ReplaySubject } from 'rxjs';
import { SourceDocumentType } from '@dougs/core/files';
import { MetricsService } from '@dougs/core/metrics';
import { toPromise } from '@dougs/core/utils';
import { Operation } from '@dougs/operations/dto';
import { ModalOperationStateService } from '@dougs/operations/shared';
import { SalesInvoice, SalesInvoiceOperationAttachment } from '@dougs/sales-invoice/dto';
import { AttachSalesInvoiceModalComponentService } from './attach-sales-invoice-modal.component.service';

@Injectable()
export class AttachSalesInvoiceItemComponentService {
  constructor(
    private readonly attachSalesInvoiceModalComponentService: AttachSalesInvoiceModalComponentService,
    private readonly modalOperationStateService: ModalOperationStateService,
    private readonly metricsService: MetricsService,
  ) {}

  private readonly salesInvoice: ReplaySubject<SalesInvoice> = new ReplaySubject(1);
  salesInvoice$: Observable<SalesInvoice> = this.salesInvoice.asObservable();

  isAttached$: Observable<boolean> = combineLatest([
    this.salesInvoice$,
    this.attachSalesInvoiceModalComponentService.operation$,
    this.attachSalesInvoiceModalComponentService.tempAttachedSalesInvoices$,
  ]).pipe(
    map(
      ([salesInvoice, operation, tempAttachedSalesInvoices]) =>
        !!salesInvoice.operationAttachments?.some(
          (operationAttachment) => operationAttachment.operation.id === operation?.id,
        ) ||
        tempAttachedSalesInvoices.some((tempAttachedSalesInvoice) => tempAttachedSalesInvoice.id === salesInvoice.id),
    ),
  );

  public setSalesInvoice(salesInvoice: SalesInvoice): void {
    this.salesInvoice.next(salesInvoice);
  }

  async onAttachSalesInvoice(): Promise<void> {
    const useTempAttachment: boolean = await toPromise(this.attachSalesInvoiceModalComponentService.useTempAttachment$);
    if (useTempAttachment) {
      const salesInvoice: SalesInvoice = await toPromise(this.salesInvoice$);
      this.attachSalesInvoiceModalComponentService.addTempSalesInvoice(salesInvoice);
    } else {
      await this.attachSalesInvoice();
    }
    this.metricsService.pushMixpanelEvent('Sales Invoice Operation Attached', { 'CTA Location': 'Operation Modal' });
  }

  async onDetachSalesInvoice(): Promise<void> {
    const useTempAttachment: boolean = await toPromise(this.attachSalesInvoiceModalComponentService.useTempAttachment$);
    if (useTempAttachment) {
      const salesInvoice: SalesInvoice = await toPromise(this.salesInvoice$);
      this.attachSalesInvoiceModalComponentService.removeTempSalesInvoice(salesInvoice);
    } else {
      await this.detachSalesInvoice();
    }
  }

  async attachSalesInvoice(): Promise<void> {
    const operation: Operation | null = await toPromise(this.attachSalesInvoiceModalComponentService.operation$);
    const initialSalesInvoice: SalesInvoice = await toPromise(this.salesInvoice$);
    if (operation) {
      const tempOperationAttachment: SalesInvoiceOperationAttachment = {
        id: 0,
        isDeletable: true,
        operation: operation as Operation,
      };
      const tempUpdatedSalesInvoice: SalesInvoice = {
        ...initialSalesInvoice,
        operationAttachments: [...(initialSalesInvoice?.operationAttachments || []), tempOperationAttachment],
      };
      this.salesInvoice.next(tempUpdatedSalesInvoice);
      const operationUpdated: Operation | null = await this.modalOperationStateService.attachSourceDocument(
        operation,
        initialSalesInvoice?.sourceDocumentId,
        SourceDocumentType.SALES_INVOICE,
      );
      if (!operationUpdated) {
        this.salesInvoice.next(initialSalesInvoice);
      }
    }
  }

  async detachSalesInvoice(): Promise<void> {
    const operation: Operation | null = await toPromise(this.attachSalesInvoiceModalComponentService.operation$);
    const initialSalesInvoice: SalesInvoice = await toPromise(this.salesInvoice$);
    const sourceDocumentAttachmentId: number | undefined = operation?.sourceDocumentAttachments?.find(
      (sda) => sda.sourceDocument.id === initialSalesInvoice.sourceDocumentId,
    )?.id;
    if (operation && sourceDocumentAttachmentId) {
      const tempUpdatedSalesInvoice: SalesInvoice = {
        ...initialSalesInvoice,
        operationAttachments: initialSalesInvoice?.operationAttachments?.filter(
          (operationAttachment) => operationAttachment.operation.id !== operation.id,
        ),
      };
      this.salesInvoice.next(tempUpdatedSalesInvoice);

      const operationUpdated: Operation | null = await this.modalOperationStateService.detachSourceDocument(
        operation,
        sourceDocumentAttachmentId,
      );
      if (!operationUpdated) {
        this.salesInvoice.next(initialSalesInvoice);
      }
    }
  }
}
