import { Routes, UrlMatchResult, UrlSegment } from '@angular/router';

export const SALES_INVOICE_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/sales-invoices-container/sales-invoices-container.component').then(
        (c) => c.SalesInvoicesContainerComponent,
      ),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/sales-invoices/sales-invoices.component').then((c) => c.SalesInvoicesComponent),
      },
      // TODO AJOUTER GUARD POUR FAIRE LES REFRESH INVOICE-DETAIL (onInit) ET REDIRIGER SI PAS D'INVOICE TROUVEE
      {
        loadComponent: () =>
          import('./components/invoice-draft-detail/invoice-draft-detail.component').then(
            (c) => c.InvoiceDraftDetailComponent,
          ),
        matcher: (url: UrlSegment[]): UrlMatchResult | null => {
          if (url.length > 0 && new RegExp(/\d+/).test(url[0].path)) {
            return {
              consumed: [url[0]],
              posParams: { id: new UrlSegment(url[0].path, {}) },
            };
          }

          return null;
        },
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
