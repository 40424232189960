import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DougsDatePipe, TrackByPipe } from '@dougs/ds';
import { SalesInvoiceType } from '@dougs/sales-invoice/dto';
import { SalesInvoiceStateService } from '@dougs/sales-invoice/shared';
import { InvoiceComponent } from '../../../components/invoice/invoice.component';
import { InvoiceBlankslateLabelPipe } from '../../../pipes/invoice-blankslate-label.pipe';
import { ShowInvoiceDivider } from '../../../pipes/invoice-divider.pipe';
import { AttachSalesInvoiceItemComponent } from '../attach-sales-invoice-item/attach-sales-invoice-item.component';

@Component({
  selector: 'dougs-attach-sales-invoice-list',
  standalone: true,
  imports: [
    CommonModule,
    DougsDatePipe,
    InvoiceBlankslateLabelPipe,
    InvoiceComponent,
    ShowInvoiceDivider,
    TrackByPipe,
    AttachSalesInvoiceItemComponent,
  ],
  templateUrl: './attach-sales-invoice-list.component.html',
  styleUrls: ['./attach-sales-invoice-list.component.scss', '../../../styles/sales-invoice-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachSalesInvoiceListComponent {
  constructor(public readonly salesInvoiceStateService: SalesInvoiceStateService) {}

  protected readonly SalesInvoiceType = SalesInvoiceType;
}
