import { Inject, Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, concatMap, filter, finalize, map, Observable, Subject, tap, withLatestFrom } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { MODAL_DATA } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { OperationsEventsService } from '@dougs/operations/shared';
import { SalesInvoice } from '@dougs/sales-invoice/dto';
import { SalesInvoiceStateService } from '@dougs/sales-invoice/shared';

@Injectable()
export class AttachSalesInvoiceModalComponentService {
  private readonly operation: BehaviorSubject<Operation | null> = new BehaviorSubject<Operation | null>(null);
  operation$: Observable<Operation | null> = this.operation.asObservable();

  private readonly isLoading: WritableSignal<boolean> = signal(false);
  isLoading$: Signal<boolean> = this.isLoading.asReadonly();

  private readonly tempAttachedSalesInvoices: BehaviorSubject<SalesInvoice[]> = new BehaviorSubject<SalesInvoice[]>([]);
  tempAttachedSalesInvoices$: Observable<SalesInvoice[]> = this.tempAttachedSalesInvoices.asObservable();

  private readonly queueScroll: Subject<void> = new Subject<void>();
  queueScroll$: Observable<void> = this.queueScroll.asObservable();

  private offset = 0;

  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly salesInvoiceStateService: SalesInvoiceStateService,
    private readonly operationsEventsService: OperationsEventsService,
    @Inject(MODAL_DATA) private readonly operationInput?: Operation,
  ) {
    if (this.operationInput) {
      this.operation.next(this.operationInput);
    }
  }

  updateOperation$: Observable<void> = this.operationsEventsService.updateOperation$.pipe(
    filter((operationUpdated: Operation) => operationUpdated?.id === this.operation.value?.id),
    map((operationUpdated: Operation) => this.operation.next(operationUpdated)),
  );

  refreshSalesInvoice$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    tap(() => this.isLoading.set(true)),
    concatMap((company: Company) => this.salesInvoiceStateService.refreshSalesInvoices(company.id, null)),
    tap(() => this.isLoading.set(false)),
    finalize(() => this.isLoading.set(false)),
  );

  loadMoreSalesInvoice$: Observable<void> = this.queueScroll$.pipe(
    withLatestFrom(this.companyStateService.activeCompanyIdChanged$),
    concatMap(([_, company]) => this.salesInvoiceStateService.refreshSalesInvoices(company.id, null, this.offset)),
  );

  useTempAttachment$: Observable<boolean> = this.operation$.pipe(map((operation) => !operation));

  addTempSalesInvoice(salesInvoice: SalesInvoice): void {
    this.tempAttachedSalesInvoices.next([...this.tempAttachedSalesInvoices.value, salesInvoice]);
  }

  removeTempSalesInvoice(salesInvoice: SalesInvoice): void {
    this.tempAttachedSalesInvoices.next(
      this.tempAttachedSalesInvoices.value.filter(
        (salesInvoiceIterated) => salesInvoiceIterated.id !== salesInvoice.id,
      ),
    );
  }

  loadMoreSalesInvoice(): void {
    this.offset++;
    this.queueScroll.next();
  }
}
