<div class="invoice-list">
  <div class="invoice-list__header invoice-list__header--attach py-8 pl-24 pr-16">
    <div class="invoice-list__header__title">
      <p class="tiny color-primary-700 bold">
        <span class="date mr-16">Date</span>
        Libellé
      </p>
    </div>
    <p class="tiny color-primary-700 bold">Client</p>
    <p class="tiny color-primary-700 bold">Statut</p>
    <p class="tiny color-primary-700 bold">Opération</p>
    <p class="tiny color-primary-700 bold text-right">Montant</p>
  </div>
  <ng-container *ngIf="salesInvoiceStateService.salesInvoices$ | async as salesInvoices">
    <ng-container
      *ngFor="let invoice of salesInvoices; let isFirst = first; let index = index; trackBy: 'id' | trackBy"
    >
      <div
        *ngIf="salesInvoices | showInvoiceDivider: index : isFirst"
        class="px-24 py-8 operation-list__date month color-gray-350"
      >
        {{ invoice.date | date: 'MMMM yyyy' | titlecase }}
      </div>
      <dougs-attach-sales-invoice-item [salesInvoice]="invoice" />
    </ng-container>
    <p *ngIf="salesInvoices.length === 0" class="blankslate-label tiny color-primary-700 p-8">
      {{ SalesInvoiceType.FINALIZED | invoiceBlankslateLabel: null }}
    </p>
  </ng-container>
</div>
